/* eslint-disable */

<template>
  <div class="flex flex-col items-center relative bg-dark main-border rounded-lg">
    <div class="flex p-6 w-full">
      <div class="flex flex-col w-1/4 main-border rounded-lg overflow-hidden bg-dark z-10">
        <span class="flex justify-center text-black text-lg font-bold px-4 py-1 w-full" style="background-color: #cd93fd"
          >QA & SW Testing Tailnet</span
        >

        <div class="flex flex-col gap-y-4 p-4">
          <div
            v-for="(endpoint, index) in endpoints"
            v-if="index < 3"
            class="flex flex-col main-border rounded-lg overflow-hidden transition duration-300"
            :id="'endpoint' + index"
          >
            <span
              class="flex items-center justify-center gap-x-2 p-2 main-bottom-border cursor-pointer"
              @click="handleEndpointClick(index)"
            >
              <font-awesome-icon icon="fa-regular fa-microchip" class="h-6 w-6 text-white" />
              <h4>{{ endpoint.ip }}</h4>
            </span>

            <span class="flex items-center gap-x-1 px-2 py-1 main-bottom-border">
              <input
                type="checkbox"
                v-model="endpoint.selected"
                class="checkbox border border-borderColor"
                :style="{
                  backgroundColor: endpoint.selected[0] === 'clean' ? '#76af64' : endpoint.selected[1] === 'clean' ? '#76af64' : '#040815',
                }"
                @change="handleChange('clean', index)"
              />
              <p style="color: #76af64">ENDPOINT IS CLEAN</p>
            </span>

            <span class="flex items-center gap-x-1 px-2 py-1 main-bottom-border">
              <input
                type="checkbox"
                v-model="endpoint.selected"
                class="checkbox border border-borderColor"
                :style="{
                  backgroundColor:
                    endpoint.selected[0] === 'infected' ? '#c36b6e' : endpoint.selected[1] === 'infected' ? '#c36b6e' : '#040815',
                }"
                @change="handleChange('infected', index)"
              />
              <p style="color: #c36b6e">ENDPOINT IS INFECTED</p>
            </span>

            <span class="flex items-center gap-x-1 px-2 py-1">
              <input
                type="checkbox"
                v-model="endpoint.selected"
                class="checkbox border border-borderColor"
                :style="{
                  backgroundColor:
                    endpoint.selected[0] === 'source' ? '#c3984e' : endpoint.selected[1] === 'source' ? '#c3984e' : '#040815',
                }"
                @change="handleChange('source', index)"
              />
              <p style="color: #c3984e">ENDPOINT IS SOURCE</p>
            </span>
          </div>

          <div class="flex flex-col main-border rounded-lg p-2">
            <div class="flex items-center gap-x-2">
              <img
                src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/249739812367248924/tailscale-logo.png"
                width="20px"
                height="20px"
                draggable="false"
              />
              <span class="flex items-center gap-x-2"><p class="text-grey">TS Exit Node - US-PS-01</p></span>
            </div>

            <div class="flex items-center justify-between mt-2">
              <font-awesome-icon icon="fa-regular fa-wave-pulse" class="h-6 w-6 mr-2 text-white" />

              <span class="flex items-center gap-x-1 main-border rounded-lg px-2 py-1">
                <font-awesome-icon icon="fa-regular fa-wifi" class="h-5 w-5 mr-2 text-white" />
                <p class="font-semibold">EN: 10.10.10.0/24</p>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col relative items-center justify-between w-3/5">
        <hr
          class="border-primary"
          style="
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            flex: 0 0 auto;
            height: 55%;
            border-left-width: 0.5rem;
            bottom: 4.7rem;
          "
        />

        <div class="flex items-center justify-center">
          <lottie-animation
            path="https://connect-cdn.intellectualpoint.com/assets/lotties/internet-pulse.json"
            :loop="true"
            :autoPlay="true"
            :speed="1"
            :width="300"
          />
        </div>

        <div class="flex flex-col main-border rounded-lg overflow-hidden bg-dark z-10">
          <span class="flex justify-center text-white text-lg font-bold px-4 py-1 w-full bg-primary">WireGuard Gateway Zone</span>

          <div
            class="flex items-center justify-center relative cursor-pointer"
            style="width: 25rem; height: 12rem"
            @click="firewallPopupActive = true"
          >
            <div class="animated-border-box-glow"></div>
            <div class="animated-border-box flex flex-col gap-y-3 items-center main-border rounded-lg p-3">
              <p class="text-primary">US-EAST-1 | IAD | PING: 71ms</p>

              <img
                src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/249739812367248924/tailscale-full-logo.png"
                width="85%"
                height="35px"
                draggable="false"
              />

              <span class="flex items-center gap-x-2 px-5 py-1 main-border rounded-lg">
                <font-awesome-icon icon="fa-regular fa-shield" class="h-5 w-5 text-white" />
                <h4>FW Traffic Logs</h4>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col main-border w-1/4 rounded-lg overflow-hidden bg-dark z-10">
        <span class="flex justify-center text-black text-lg font-bold px-4 py-1 w-full" style="background-color: #a2ff97"
          >Git DevOps CI/CD Tailnet</span
        >

        <div class="flex flex-col gap-y-4 p-4">
          <div
            v-for="(endpoint, index) in endpoints"
            v-if="index > 2"
            class="flex flex-col main-border rounded-lg overflow-hidden transition duration-300"
            :id="'endpoint' + index"
          >
            <span
              class="flex items-center justify-center gap-x-2 p-2 main-bottom-border cursor-pointer"
              @click="handleEndpointClick(index)"
            >
              <font-awesome-icon icon="fa-regular fa-microchip" class="h-6 w-6 text-white" />
              <h4>{{ endpoint.ip }}</h4>
            </span>

            <span class="flex items-center gap-x-1 px-2 py-1 main-bottom-border">
              <input
                type="checkbox"
                v-model="endpoint.selected"
                class="checkbox border border-borderColor"
                :style="{
                  backgroundColor: endpoint.selected[0] === 'clean' ? '#76af64' : endpoint.selected[1] === 'clean' ? '#76af64' : '#040815',
                }"
                @change="handleChange('clean', index)"
              />
              <p style="color: #76af64">ENDPOINT IS CLEAN</p>
            </span>

            <span class="flex items-center gap-x-1 px-2 py-1 main-bottom-border">
              <input
                type="checkbox"
                v-model="endpoint.selected"
                class="checkbox border border-borderColor"
                :style="{
                  backgroundColor:
                    endpoint.selected[0] === 'infected' ? '#c36b6e' : endpoint.selected[1] === 'infected' ? '#c36b6e' : '#040815',
                }"
                @change="handleChange('infected', index)"
              />
              <p style="color: #c36b6e">ENDPOINT IS INFECTED</p>
            </span>

            <span class="flex items-center gap-x-1 px-2 py-1">
              <input
                type="checkbox"
                v-model="endpoint.selected"
                class="checkbox border border-borderColor"
                :style="{
                  backgroundColor:
                    endpoint.selected[0] === 'source' ? '#c3984e' : endpoint.selected[1] === 'source' ? '#c3984e' : '#040815',
                }"
                @change="handleChange('source', index)"
              />
              <p style="color: #c3984e">ENDPOINT IS SOURCE</p>
            </span>
          </div>

          <div class="flex flex-col main-border rounded-lg overflow-hidden transition duration-300" :id="'endpoint'">
            <span class="flex items-center justify-center gap-x-2 p-2 main-bottom-border">
              <font-awesome-icon icon="fa-regular fa-microchip" class="h-6 w-6 text-white opacity-50" />
              <h4 class="opacity-50">10.60.0.74</h4>
            </span>

            <div class="flex flex-col items-center justify-center p-4 opacity-50">
              <h2>DECOMISSIONED</h2>
              <h2>HOST</h2>
            </div>
          </div>

          <div class="flex flex-col main-border rounded-lg p-2">
            <div class="flex items-center gap-x-2">
              <img
                src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/249739812367248924/tailscale-logo.png"
                width="20px"
                height="20px"
                draggable="false"
              />
              <span class="flex items-center gap-x-2"><p class="text-grey">TS Exit Node - US-CT-01</p></span>
            </div>

            <div class="flex items-center justify-between mt-2">
              <font-awesome-icon icon="fa-regular fa-wave-pulse" class="h-6 w-6 mr-2 text-white" />

              <span class="flex items-center gap-x-1 main-border rounded-lg px-2 py-1">
                <font-awesome-icon icon="fa-regular fa-wifi" class="h-5 w-5 mr-2 text-white" />
                <p class="font-semibold">EN: 10.60.0.0/24</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="absolute flex justify-center w-3/4 border-primary" style="bottom: 4.7rem; border-top-width: 0.5rem" />

    <movable-popup
      :refVal="'endpointLogPopup' + index"
      :title="endpoints[index].ip + ' Logs - Click Here to Drag This Popup'"
      :active="endpoints[index].popupActive === true"
      v-on:close="endpoints[index].popupActive = false"
      v-for="(endpoint, index) in endpoints"
      :key="index"
    >
      <template v-slot:content>
        <div class="h-fit overflow-y-auto" style="max-height: 40rem">
          <div v-html="logs[index]" class="rounded-lg text-grey" />
        </div>
      </template>
    </movable-popup>

    <movable-popup
      :refVal="'firewallPopup'"
      :title="'FW Traffic Logs - Click Here to Drag This Popup'"
      :active="firewallPopupActive === true"
      v-on:close="firewallPopupActive = false"
      width="900px"
      style="max-width: 900px !important"
    >
      <template v-slot:content>
        <div class="flex gap-x-6 w-full">
          <div class="flex flex-col">
            <h5>TIMESTAMP</h5>
            <div class="text-grey">
              <p>06/21/2023 16:01:44</p>
              <p>06/21/2023 16:01:58</p>
              <p>06/21/2023 16:17:06</p>
              <p>06/21/2023 16:27:36</p>
              <p>06/21/2023 16:28:06</p>
              <p>06/21/2023 16:33:31</p>
              <p>06/21/2023 16:35:36</p>
              <p>06/21/2023 23:58:36</p>
              <p>06/21/2023 23:58:43</p>
              <p>06/21/2023 23:58:45</p>
              <p>06/22/2023 02:31:36</p>
              <p>06/22/2023 02:31:45</p>
              <p>06/22/2023 02:31:51</p>
              <p>06/22/2023 02:31:02</p>
              <p>06/22/2023 02:39:11</p>
              <p>06/22/2023 02:39:12</p>
              <p>06/22/2023 02:39:32</p>
              <p>06/22/2023 13:37:36</p>
              <p>06/22/2023 13:39:43</p>
              <p>06/22/2023 13:45:04</p>
              <p>06/22/2023 13:47:44</p>
              <p>06/22/2023 13:52:57</p>
              <p>06/22/2023 13:53:01</p>
              <p>06/22/2023 14:30:04</p>
              <p>06/22/2023 14:30:04</p>
            </div>
          </div>

          <div class="flex flex-col">
            <div class="text-grey">
              <h5>SRCIP</h5>
              <p>10.60.0.57</p>
              <p>10.10.10.192</p>
              <p>10.10.10.189</p>
              <p>10.10.10.114</p>
              <p>10.60.0.45</p>
              <p>10.60.0.57</p>
              <p>10.10.10.192</p>
              <p>10.60.0.45</p>
              <p>10.60.0.45</p>
              <p>10.60.0.45</p>
              <p>10.60.0.57</p>
              <p>10.10.10.189</p>
              <p>10.60.0.57</p>
              <p>10.10.10.189</p>
              <p>10.10.10.114</p>
              <p>10.60.0.57</p>
              <p>10.10.10.192</p>
              <p>10.10.10.189</p>
              <p>10.10.10.189</p>
              <p>10.60.0.57</p>
              <p>10.60.0.45</p>
              <p>10.60.0.57</p>
              <p>10.10.10.192</p>
              <p>10.60.0.45</p>
              <p>10.10.10.192</p>
            </div>
          </div>

          <div class="flex flex-col">
            <div class="text-grey">
              <h5>DESTIP</h5>
              <p>57.203.54.183</p>
              <p>57.203.54.221</p>
              <p>10.60.0.45</p>
              <p>10.60.0.45</p>
              <p>10.10.10.114</p>
              <p>10.10.10.189</p>
              <p>10.60.0.45</p>
              <p>10.10.10.114</p>
              <p>10.10.10.189</p>
              <p>10.10.10.192</p>
              <p>10.10.10.114</p>
              <p>57.203.55.29</p>
              <p>57.203.56.201</p>
              <p>57.203.55.234</p>
              <p>57.203.53.89</p>
              <p>57.203.55.19</p>
              <p>57.203.56.113</p>
              <p>10.60.0.57</p>
              <p>10.60.0.57</p>
              <p>10.10.10.192</p>
              <p>10.10.10.114</p>
              <p>10.10.10.189</p>
              <p>10.60.0.45</p>
              <p>57.203.56.231</p>
              <p>57.203.56.143</p>
            </div>
          </div>

          <div class="flex flex-col">
            <h5>DESTPORT</h5>
            <div class="text-grey">
              <p>443</p>
              <p>443</p>
              <p>135</p>
              <p>445</p>
              <p>135</p>
              <p>135</p>
              <p>135</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>445</p>
              <p>8080</p>
              <p>443</p>
              <p>443</p>
              <p>8080</p>
              <p>8080</p>
              <p>443</p>
              <p>445</p>
              <p>135</p>
              <p>135</p>
              <p>445</p>
              <p>135</p>
              <p>335</p>
              <p>443</p>
              <p>443</p>
            </div>
          </div>

          <div class="flex flex-col">
            <h5>APP</h5>
            <div class="text-grey">
              <p>ssl</p>
              <p>ssl</p>
              <p>rpc</p>
              <p>smbv1</p>
              <p>rpc</p>
              <p>rpc</p>
              <p>smbv2</p>
              <p>icmp</p>
              <p>icmp</p>
              <p>icmp</p>
              <p>smbv2</p>
              <p>http</p>
              <p>ssl</p>
              <p>http</p>
              <p>http</p>
              <p>ssl</p>
              <p>ssl</p>
              <p>smbv3</p>
              <p>rpc</p>
              <p>rpc</p>
              <p>smbv3</p>
              <p>rpc</p>
              <p>smbv3</p>
              <p>ssl</p>
              <p>ssl</p>
            </div>
          </div>

          <div class="flex flex-col">
            <h5>ACTION</h5>
            <div class="text-grey">
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
              <p>Permit</p>
            </div>
          </div>

          <div class="flex flex-col">
            <h5>SENTBYTES</h5>
            <div class="text-grey">
              <p>6953</p>
              <p>9201</p>
              <p>175</p>
              <p>345</p>
              <p>754</p>
              <p>643</p>
              <p>649</p>
              <p>128</p>
              <p>128</p>
              <p>128</p>
              <p>1874</p>
              <p>7203</p>
              <p>9953</p>
              <p>4937</p>
              <p>8201</p>
              <p>1284</p>
              <p>9341</p>
              <p>1874</p>
              <p>673</p>
              <p>693</p>
              <p>482</p>
              <p>545</p>
              <p>876</p>
              <p>9901</p>
              <p>10092</p>
            </div>
          </div>

          <div class="flex flex-col">
            <h5>RECEVIEDBYTES</h5>
            <div class="text-grey">
              <p>99427</p>
              <p>199386</p>
              <p>1504</p>
              <p>34757</p>
              <p>4771</p>
              <p>2355</p>
              <p>5644</p>
              <p>128</p>
              <p>128</p>
              <p>128</p>
              <p>23874</p>
              <p>75997</p>
              <p>199730</p>
              <p>84937</p>
              <p>133183</p>
              <p>9102854</p>
              <p>9938</p>
              <p>23874</p>
              <p>41358</p>
              <p>1952</p>
              <p>3505</p>
              <p>9063</p>
              <p>8068</p>
              <p>199730</p>
              <p>209938</p>
            </div>
          </div>
        </div>
      </template>
    </movable-popup>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import MovablePopup from '../../../../../../../../components/popup/MovablePopup';
import LottieAnimation from '../../../../../../../../components/lottie/LottieAnimation';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      endpointSelected: 0,
      firewallPopupActive: false,

      endpoints: [
        {
          ip: '10.10.10.189',
          answer: ['infected', ''],
          selected: ['', ''],
          popupActive: false,
        },
        {
          ip: '10.10.10.192',
          answer: ['infected', ''],
          selected: ['', ''],
          popupActive: false,
        },
        {
          ip: '10.10.10.114',
          answer: ['infected', ''],
          selected: ['', ''],
          popupActive: false,
        },
        {
          ip: '10.60.0.45',
          answer: ['infected', ''],
          selected: ['', ''],
          popupActive: false,
        },
        {
          ip: '10.60.0.57',
          answer: ['infected', 'source'],
          selected: ['', ''],
          popupActive: false,
        },
      ],

      logs: [
        `[*] <span class='text-primary'>[INFO]</span> 06/21/2020 14:30 - Scheduled Scan Initiated<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:31 - Checking For Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:32 - No Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:33 - Checking For Definition Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:34 - No Definition Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:35 - Scan Type = Full<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:36 - Scan Start<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:37 - Scanning System Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:38 - Scanning Temporary Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:39 - Scanning Services<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:40 - Scanning Boot Sector<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:41 - Scan Complete<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:42 - Files Removed: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:43 - Files Quarantined: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:44 - Boot Sector: Clean<br>
[*] <span class='text-primary'>[INFO]</span>06/21/2023 14:45 - Next Scheduled Scan: 06/22/2023 14:30<br>
[#] <span class='text-primary'>[INFO]</span> 06/22/2023 02:31 - Scheduled Scan Disabled By Process svch0st.exe<br>
[#] <span class='text-primary'>[INFO]</span> 06/22/2023 02:32 - Scheduled Update DIsabled by Process scvh0st.exe`,

        `[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:30 - Scheduled Scan Initiated<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:31 - Checking For Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:32 - No Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:33 - Checking For Definition Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:34 - No Definition Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:35 - Scan Type = Full<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:36 - Scan Start<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:37 - Scanning System Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:38 - Scanning Temporary Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:39 - Scanning Services<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:40 - Scanning Boot Sector<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:41 - Scan Complete<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:42 - Files Removed: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:43 - Files Quarantined: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:44 - Boot Sector: Clean<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:45 - Next Scheduled Scan: 06/22/2023 14:30<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:30 - Scheduled Scan Initiated<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:31 - Checking For Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:32 - No Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:33 - Checking For Definition Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:34 - Update Available v10.2.3.4440<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:33 - Downloading Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:35 - Definition Update Complete<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:35 - Scan Type = Full<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:36 - Scan Start<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:37 - Scanning System Files<br>
[#] <span class='text-warning'>[WARN]</span> 06/22/2023 14:37 - File Found svch0st.exe Match Definition v10.2.3.4440<br>
[#] <span class='text-warning'>[WARN]</span> 06/22/2023 14:37 - File Quarantined svch0st.exe<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:38 - Scanning Temporary Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:39 - Scanning Services<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:40 - Scanning Boot Sector<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:41 - Scan Complete<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:42 - Files Removed: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:43 - Files Quarantined: 1<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:44 - Boot Sector: Clean<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:45 - Next Scheduled Scan: 6/23/2023 14:30`,

        `[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:30 - Scheduled Scan Initiated<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:31 - Checking For Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:32 - No Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:33 - Checking For Definition Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:34 - No Definition Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:35 - Scan Type = Full<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:36 - Scan Start<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:37 - Scanning System Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:38 - Scanning Temporary Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:39 - Scanning Services<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:40 - Scanning Boot Sector<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:41 - Scan Complete<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:42 - Files Removed: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:43 - Files Quarantined: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:44 - Boot Sector: Clean<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:45 - Next Scheduled Scan: 06/22/2023 14:30<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:30 - Scheduled Scan Initiated<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:31 - Checking For Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:32 - No Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:33 - Checking For Definition Update<br>
[!] <span class='text-danger'>[FAIL]</span> 06/22/2023 14:34 - Unable To Reach Update Server<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:35 - Scan Type = Full<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:36 - Scan Start<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:37 - Scanning System Files<br>
[#] <span class='text-warning'>[WARN]</span> 06/22/2023 14:37 - File svch0st.exe Match Heuristic Pattern 0c09488c08d0f3k<br>
[!] <span class='text-danger'>[FAIL]</span> 06/22/2023 14:37 - Unable To Quarantine File svch0st.exe<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:38 - Scanning Temporary Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:39 - Scanning Services<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:40 - Scanning Boot Sector<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:41 - Scan Complete<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:42 - Files Removed: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:43 - Files Quarantined: 0<br>
[#] <span class='text-warning'>[WARN]</span> 06/22/2023 14:43 - File Quarantine File<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:44 - Boot Sector: Clean<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:45 - Next Scheduled Scan: 6/23/2023 14:30`,

        `[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:30 - Scheduled Scan Initiated<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:31 - Checking For Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:32 - No Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:33 - Checking For Definition Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:34 - No Definition Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:35 - Scan Type = Full<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:36 - Scan Start<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:37 - Scanning System Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:38 - Scanning Temporary Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:39 - Scanning Services<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:40 - Scanning Boot Sector<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:41 - Scan Complete<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:42 - Files Removed: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:43 - Files Quarantined: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:44 - Boot Sector: Clean<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:45 - Next Scheduled Scan: 06/22/2023 14:30<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:30 - Scheduled Scan Initiated<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:31 - Checking For Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:32 - No Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:33 - Checking For Definition Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:34 - Update Available v10.2.3.4440<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:33 - Downloading Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:35 - Definition Update Complete<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:35 - Scan Type = Full<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:36 - Scan Start<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:37 - Scanning System Files<br>
[#] <span class='text-warning'>[WARN]</span> 06/22/2023 14:37 - File Found svch0st.exe Match Definition v10.2.3.4440<br>
[#] <span class='text-warning'>[WARN]</span> 06/22/2023 14:37 - File Quarantined svch0st.exe<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:38 - Scanning Temporary Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:39 - Scanning Services<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:40 - Scanning Boot Sector<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:41 - Scan Complete<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:42 - Files Removed: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:43 - Files Quarantined: 1<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:44 - Boot Sector: Clean<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:45 - Next Scheduled Scan: 6/23/2023 14:30`,

        `[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:30 - Scheduled Scan Initiated<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:31 - Checking For Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:32 - No Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:33 - Checking For Definition Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:34 - No Definition Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:35 - Scan Type = Full<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:36 - Scan Start<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:37 - Scanning System Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:38 - Scanning Temporary Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:39 - Scanning Services<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:40 - Scanning Boot Sector<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:41 - Scan Complete<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:42 - Files Removed: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:43 - Files Quarantined: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:44 - Boot Sector: Clean<br>
[*] <span class='text-primary'>[INFO]</span> 06/21/2023 14:45 - Next Scheduled Scan: 06/22/2023 14:30<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:30 - Scheduled Scan Initiated<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:31 - Checking For Update<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:32 - No Update Available<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:33 - Checking For Definition Update<br>
[!] <span class='text-danger'>[FAIL]</span> 06/22/2023 14:34 - Unable To Reach Update Server<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:35 - Scan Type = Full<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:36 - Scan Start<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:37 - Scanning System Files<br>
[#] <span class='text-warning'>[WARN]</span> 06/22/2023 14:37 - File svch0st.exe Match Heuristic Pattern 0c09488c09d0f3k<br>
[!] <span class='text-danger'>[FAIL]</span> 06/22/2023 14:37 - Unable To Quarantine File svch0st.exe<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:38 - Scanning Temporary Files<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:39 - Scanning Services<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:40 - Scanning Boot Sector<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:41 - Scan Complete<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:42 - Files Removed: 0<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:43 - Files Quarantined: 0<br>
[#] <span class='text-warning'>[WARN]</span> 06/22/2023 14:43 - File Quarantine File<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:44 - Boot Sector: Clean<br>
[*] <span class='text-primary'>[INFO]</span> 06/22/2023 14:45 - Next Scheduled Scan: 6/23/2023 14:30`,

        `TIMESTAMP				SRCIP			DESTIP			DESTPORT	APP		Action	SENTBYTES	RECEIVEDBYTES<br>
06/21/2023 16:01:44		10.60.0.57		57.203.54.183	443			ssl		Permit	6953		99427<br>
06/21/2023 16:01:58		10.10.10.192	57.203.54.221	443			ssl		Permit	9201		199386<br>
06/21/2023 16:17:06		10.10.10.189	10.60.0.45		135			rpc		Permit	175			1504<br>
06/21/2023 16:27:36		10.10.10.114	10.60.0.45		445			smbv1	Permit	345			34757<br>
06/21/2023 16:28:06		10.60.0.45		10.10.10.114	135			rpc		Permit	754			4771<br>
06/21/2023 16:33:31		10.60.0.57		10.10.10.189	135			rpc		Permit	643			2355<br>
06/21/2023 16:35:36		10.10.10.192	10.60.0.45		135			smbv2	Permit	649			5644<br>
06/21/2023 23:58:36		10.60.0.45		10.10.10.114				icmp	Permit	128			128<br>
06/21/2023 23:58:43		10.60.0.45		10.10.10.189				icmp	Permit	128			128<br>
06/21/2023 23:58:45		10.60.0.45		10.10.10.192				icmp	Permit	128			128<br>
06/22/2023 02:31:36		10.60.0.57		10.10.10.114	445			smbv2	Permit	1874		23874<br>
06/22/2023 02:31:45		10.10.10.189	57.203.55.29	8080		http	Permit	7203		75997<br>
06/22/2023 02:31:51		10.60.0.57		57.203.56.201	443			ssl		Permit	9953		199730<br>
06/22/2023 02:31:02		10.10.10.189	57.203.55.234	443			http	Permit	4937		84937<br>
06/22/2023 02:39:11		10.10.10.114	57.203.53.89	8080		http	Permit	8201		133183<br>
06/22/2023 02:39:12		10.60.0.57		57.203.55.19	8080		ssl		Permit	1284		9102854<br>
06/22/2023 02:39:32		10.10.10.192	57.203.56.113	443			ssl		Permit	9341		9938<br>
06/22/2023 13:37:36		10.10.10.189	10.60.0.57		445			smbv3	Permit	1874		23874<br>
06/22/2023 13:39:43		10.10.10.189	10.60.0.57		135			rpc		Permit	673			41358<br>
06/22/2023 13:45:04		10.60.0.57		10.10.10.192	135			rpc		Permit	693			1952<br>
06/22/2023 13:47:44		10.60.0.45		10.10.10.114	445			smbv3	Permit	482			3505<br>
06/22/2023 13:52:57		10.60.0.57		10.10.10.189	135			rpc		Permit	545			9063<br>
06/22/2023 13:53:01		10.10.10.192	10.60.0.45		335			smbv3	Permit	876			8068<br>
06/22/2023 14:30:04		10.60.0.45		57.203.56.231	443			ssl		Permit	9901		199730<br>
06/22/2023 14:30:04		10.10.10.192	57.203.56.143	443			ssl		Permit	10092		209938`,
      ],
    };
  },
  methods: {
    markSimulation() {
      let total_score = 0;

      this.endpoints.map((endpoint, index) => {
        if (index <= 3) {
          if (endpoint.selected[0] === 'infected' && endpoint.selected[1] === '') {
            total_score += 1;
          } else if (endpoint.selected[1] === 'infected' && endpoint.selected[0] === '') {
            total_score += 1;
          } else {
            const element = document.getElementById(`endpoint${index}`);
            if (element) {
              element.classList.add('incorrect');
            }
          }
        } else {
          if (endpoint.selected[0] === 'source' && endpoint.selected[1] === 'infected') {
            total_score += 1;
          } else if (endpoint.selected[1] === 'source' && endpoint.selected[0] === 'infected') {
            total_score += 1;
          } else {
            const element = document.getElementById(`endpoint${index}`);
            if (element) {
              element.classList.add('incorrect');
            }
          }
        }
      });

      if (total_score === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', total_score);
      } else {
        this.$emit('simulation_getting_there', total_score);
      }
    },

    handleEndpointClick(index) {
      this.endpoints[index].popupActive = true;
    },

    handleChange(selection, index) {
      const element = document.getElementById(`endpoint${index}`);
      if (element) {
        element.classList.remove('incorrect');
      }
      if (this.endpoints[index].selected[0] === selection) {
        this.endpoints[index].selected[0] = '';
      } else if (this.endpoints[index].selected[1] === selection) {
        this.endpoints[index].selected[1] = '';
      } else {
        if (this.endpoints[index].selected[0] === '') {
          this.endpoints[index].selected[0] = selection;
        } else if (this.endpoints[index].selected[1] === '') {
          this.endpoints[index].selected[1] = selection;
        }
      }
    },
  },
  components: {
    Prism,
    vSelect,
    MovablePopup,
    LottieAnimation,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  background-color: transparent;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
}

.incorrect {
  border: 1px solid #c36b6e !important;
}

.animated-border-box,
.animated-border-box-glow {
  max-height: 10rem;
  max-width: 250px;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  /* Border Radius */
  border-radius: 10px;
}

.animated-border-box-glow {
  overflow: hidden;
  /* Glow Blur */
  filter: blur(4px);
}

.animated-border-box:before,
.animated-border-box-glow:before {
  content: '';
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  width: 99999px;
  height: 99999px;
  background-repeat: no-repeat;
  background-position: 0 0;
  /*border color, change middle color*/
  background-image: conic-gradient(rgba(0, 0, 0, 0), #535d70, rgba(0, 0, 0, 0) 25%);
  /* change speed here */
  animation: rotate 8s linear infinite;
}

.animated-border-box:after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 1.5px;
  top: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 7px;
  background-color: #040815;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}
</style>
